import React from 'react'
import './CGU.css'



const CGU = () => {

  return (




    <div id='CGU'>
      <div id='CGU_content'>
        <h1>Conditions Générales d'Utilisation du site</h1>

        <h2 className='CGU_content_h2'>1. Champ d’application et acceptation des<br/> Conditions Générales d’Utilisation  </h2>

        <div>
          <p>         
            Votre accès au Site et aux services proposés par Interlogistique Batiment Services sur son Site (les « Services ») ainsi que leur utilisation sont régis par les présentes Conditions Générales d’Utilisation (les « Conditions d’Utilisation »).
          </p>
          <p>
          Les présentes Conditions d’Utilisation sont rédigées en langue française. Toute utilisation du Site et des Services emporte de plein droit l’application des présentes Conditions d’Utilisation. Vous serez réputé les avoir acceptées et être lié par elles.
          </p>

        </div>

        <h2 className='CGU_content_h2'>2. Disponibilité du Site</h2>
        
        <div>
          <p>
          L’accès au Site et les Services sont fournis sur la base d’un service « en l’état » et accessible en fonction de sa disponibilité.
          </p>
          <p>
            Interlogistique Batiment Services se réserve le droit de modifier, éditer, supprimer, suspendre ou interrompre, temporairement ou définitivement,le Site et son contenu (ou toute portion de ce dernier) et ceci sans préavis, indépendamment des opérations de maintenance programmées.
          </p>
          

        </div>

        <h2 className='CGU_content_h2'>3. Propriété Intellectuelle</h2>
        
        <div>
          <p>
            Il est indiqué que notre Site y compris sa structure générale, ses textes, son contenu, sa charte graphique ainsi que les marques, les logos, les créations graphiques, animés ou non, les noms de domaines,les photographies, les sons et vidéos, les applications logicielles, et tous les autres éléments, qu’ils soient visuels et/ou sonores, 
          </p>
          <p>           
            Figurant sur notre Site (le « Contenu du Site ») sont protégés par la législation Congolaise relative à la propriété intellectuelle en vigueur, et en particulier par le droit d’auteur,les dessins et modèles et le droit des marques. Interlogistique Batiment Services est propriétaire et/ou titulaire de l’ensemble des droits y afférent.
          </p>
        </div>

        <h2 className='CGU_content_h2'>4. Utilisation du Site</h2>
        
        <div>
          <p>
            Le droit d’accès et l’utilisation du Site sont limités à votre utilisation personnelle. A ce titre et conformément à la législation applicable, seule utilisation du Contenu du Site pour un usage privé est autorisée.
          </p>
          <p>
            En conséquence, toute reproduction, représentation, modification et, de manière plus générale, tout exploitation, quelle qu’elle soit, du Contenu du Site sans autorisation expresse et préalable de Interlogistique-Batiment-Services est strictement interdite.
          </p>
          <p>
           Il est précisé que toute exploitation non autorisée du Contenu du Site est constitutive d’actes de contrefaçon de droits d’auteur et/ou droit des signes distinctifs et engage votre responsabilité
          </p>
        </div>

        <h2 className='CGU_content_h2'>5. Suspension de l’accès au Site et/ou aux Services</h2>
        <div>
          <p>
          Interlogistique Batiment Services se réserve le droit de suspendre ou de résilier votre accès au Site et aux Services si vous n’utilisez pas le Site dans le respect des présentes Conditions d’Utilisation et si vous diffuser sur le Site tous contenus contraires à la loi et/ou à l’ordre public.
          </p>
        </div>

        <h2 className='CGU_content_h2'>6. Exclusion de responsabilité</h2>
        <div>
          <p>
          Interlogistique Batiment Services s’engage à employer ses meilleurs efforts pour assurer l’exploitation du Site dans les meilleures conditions. En tout hypothèse, Interlogistique Batiment Services exclue la responsabilité découlant ou relative à l’accès ou l’utilisation de son Site dans pour toute perte ou tout dommage subis, en particulier, du fait d’une indisponibilité ou d’une défaillance technique du Site, d’une perte des données, Des pertes causées par une attaque pour dénis de services, des virus ou tout autre matériel technologiquement nuisible et d’une perte de recettes ou profit ou économies anticipées.
          </p>
        </div>

        <h2 className='CGU_content_h2'>7. Services </h2>
        <div>
          <p>
          Sauf convention écrite expresse, les Services proposés par Interlogistique Batiment Services sur son Site sont soumis aux Conditions Contractuelles de Interlogistique Batiment Services qui sont composées des Conditions Générales de Vente, des Conditions Générales de Transport et des Conditions Particulières de Remboursement.
          </p>
        </div>

        <h2 className='CGU_content_h2'>8. Modification des Conditions d’Utilisation</h2>
        <div>
          <p>
          Interlogistique Batiment Services se réserve le droit de modifier à tout moment les présentes Conditions d’Utilisation.Toute nouvelle version des Conditions d’Utilisation sera postée sur cette page. Interlogistique Batiment Services vous invite à consulter cette page lors de chaque visite en vue de prendre connaissance de la dernière version en vigueur desdites Conditions.Vous serez réputé avoir accepté la version modifiée des Conditions d’Utilisation lorsque vous utilisez le Site et les Services postérieurement à leur modification.
          </p>
        </div>

        <h2 className='CGU_content_h2'>9. Loi applicable et juridiction compétente</h2>
        <div>
          <p>
          Les présentes Conditions d’Utilisation sont régies par la loi Congolaise.Les tribunaux du la République du Congo auront la compétence exclusive pour régler tout litige relatif à la validité, l’application,l’interprétation ou qui serait en relation avec les présentes Conditions d’Utilisation.
          </p>
        </div>

      </div>
    
    </div>
   
  )
}

export default CGU