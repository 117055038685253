import React from 'react'
import './Footer.css'

import logo from '../../assets/logo/logo-header.svg'
import wa from '../../assets/icons/whatsapp.svg'
import fb from '../../assets/icons/facebook.svg'
import ld from '../../assets/icons/linkedin.svg'

import { NavLink } from 'react-router-dom'

const Footer = (props) => {
  return (
    <>
    <footer >

      <section id='bloc1' className='container' >
        <div id='bloc1div1'>
        
          <div>
              <img id="logoF" src={logo} alt="Logo ornoir"/>
          </div>
      
          <div id='RS'>
              <img src={wa} alt="join_whatsapp" onClick={e=>window.location.href='https://wa.me/242069059797?text=Bonjour Ornoir, je vous contacte pour...'}/>
              <img src={fb} alt="join_fb"  onClick={e=>window.location.href='https://www.facebook.com/Ornoir.a'} />
              <img src={ld} alt="join_linkedin" onClick={e=>window.location.href='https://www.linkedin.com/company/ornoir-agency'}/>
              
          </div>
        
        </div>

        <div>
            <ul>
                <li>Services</li>
                <li>Market</li>
                <li>Graphique & Print</li>
                <li>web & App</li>
                <li>Design produits</li>
            </ul>
        </div>

        <div id='bloc1div2'>
          <div id="col2">
            <h1>Newsletter</h1>
            <span>Nos tips et conseils dans votre boîte mail.</span>
            <div>
              <input type="mail" name='newsletter_mail' placeholder='Entrez votre mail ici'/>
              <button onClick={e=>alert(e.currentTarget.previousSibling.value)}>S'inscrire</button>
            </div>
            <p>
            Votre adresse mail ne sera utilisée que pour vous envoyer notre newsletter. Vous pourrez vous désinscrire à tout moment via le lien prévu dans la newsletter.
            </p>

          </div>
        </div>
      </section>

      <section id='bloc2'>
        <ul>
          <li onClick={props.Anchor}>
            <NavLink to='./ML' >Mentions légales </NavLink>
            <a href="http://"> </a>
          </li>
          <li onClick={props.Anchor}>
            <NavLink to='./CGU'>Politique de confidentialité</NavLink>
            <a href="http://">  </a>
          </li>
          <li onClick={props.Anchor}>
            <NavLink to='./DPC'>CGV </NavLink>
            <a href="http://"> </a>
          </li>
          <li>© Ornoir Agency 2022</li>
        </ul>
      </section>

    </footer>
      
    </>
  )
}

export default Footer